
























import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { VProgressLinear, VForm, VDivider } from "vuetify/lib";

@Component({ components: { VProgressLinear, VForm, VDivider } })
export default class CForm extends Vue {
    @Prop({ type: Boolean }) loading!: boolean | false;
    @Prop({ type: String }) title!: string;
    @Prop({ type: Boolean, default: true }) allowClose!: boolean;

    validate() {
        return (this.$refs.form as any).validate();
    }

    reset() {
        return (this.$refs.form as any).reset();
    }

    resetValidation() {
        return (this.$refs.form as any).resetValidation();
    }

    @Emit()
    close() {
        return;
    }

    @Emit()
    submit() {
        return true;
    }
}
