






import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { GoogleLogin } from "vue-google-login";
import { ProfileModule } from "@/store/profile-module";
const profileStore = getModule(ProfileModule);

@Component({ components: { GoogleLogin } })
export default class LoginGoogle extends Vue {
    @Prop({ type: Boolean }) disabled!: boolean;

    params = {
        client_id: process.env.VUE_APP_GOOGLE,
    };

    get getClass() {
        return this.disabled
            ? "v-btn v-btn--block v-btn--disabled v-btn--has-bg theme--light v-size--default"
            : "v-btn v-btn--block v-btn--contained theme--light v-size--default primary";
    }

    @Emit("logged-in")
    onSuccess(googleUser: any) {
        this.$emit("processing");
        return profileStore.verify({ token: googleUser.getAuthResponse().id_token, type: 2 }).then((res) => res.data);
    }

    @Emit("login-failed")
    onFailure(err: any) {
        return err;
    }
}
