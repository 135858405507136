




import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
const profileStore = getModule(ProfileModule);

@Component({ components: {} })
export default class LoginFacebook extends Vue {
    @Prop({ type: Boolean }) disabled!: boolean;

    async created() {
        await this.initFacebook();
    }

    logIn() {
        (window as any).FB.login((response: any) => {
            if (response.authResponse) {
                this.$emit("processing");
                profileStore
                    .verify({ token: response.authResponse.accessToken, type: 1 })
                    .then((res) => this.$emit("logged-in", res.data));
            }
        });
    }

    async initFacebook() {
        return new Promise((resolve) => {
            (window as any).fbAsyncInit = function() {
                (window as any).FB.init({
                    appId: process.env.VUE_APP_FACEBOOK,
                    xfbml: false,
                    version: "v18.0",
                    cookie: true,
                });
                (window as any).FB.AppEvents.logPageView();
                resolve("SDK Loaded!");
            };
            (function(d, s, id) {
                const fjs: any = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                const js: any = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        });
    }
}
