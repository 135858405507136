



























import { Component, Vue } from "vue-property-decorator";
import LoginFacebook from "./LoginFacebook.vue";
import LoginGoogle from "./LoginGoogle.vue";
import LoginEmailDialog from "./LoginEmailDialog.vue";

@Component({ components: { LoginEmailDialog, LoginFacebook, LoginGoogle } })
export default class Login extends Vue {
    emailRequired = false;
    loginFailed = false;
    processing = false;

    loggedIn(emailSupplied: boolean) {
        this.loginFailed = false;
        this.processing = false;
        if (!emailSupplied) {
            (this.$refs.dialog as any).open();
        } else {
            this.redirect();
        }
    }

    loggedInFailed(err: any) {
        this.loginFailed = true;
        this.processing = false;
        (this as any).$appInsights.trackException({ exception: err });
    }

    redirect() {
        // redirect to home or to the redirect url.
        if (this.$route.query.returnuri) {
            this.$router.push({ path: this.$route.query.returnuri.toString() });
        } else {
            this.$router.push({ name: "home" });
        }
    }
}
