
















import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { required, email } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";

const profileStore = getModule(ProfileModule);
@Component({ components: { CForm } })
export default class LoginEmailDialog extends Vue {
    validators = { required, email };
    dialog = false;
    email = "";

    open() {
        this.dialog = true;
    }

    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        profileStore.setEmail(this.email).then(() => this.$emit("complete"));
    }
}
